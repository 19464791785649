
















import useUser from '@/use/user'
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { OUsersList: () => import('@/components/organisms/o-users-list.vue') },

  setup(_, { root }) {
    const { hasAccessTo } = useUser({ root })

    const model = reactive({
      search: ''
    })

    return { hasAccessTo, model }
  }
})
